button, a {
  background-color: #0d0f11;
  margin: 20px;
  padding: 10px;
  width: calc(20% - 40px);
  color: inherit;
  border: none;
  font-size: calc(10px + 1vmin);
  cursor: pointer;
  outline: inherit;
  text-decoration: none;
}

.PersonCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  width: calc(20% - 20px);

  &.big {
    width: initial;
  }

  .colorLine {
    align-self: stretch;
    width: 5px;
    min-width: 5px;
  }

  .bio {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
    font-size: calc(10px + 1vmin);
    margin: 5px;

    .bigName {
      font-size: calc(10px + 3vmin);
    }

    .bigLastname {
      font-weight: bold;
      font-size: calc(10px + 4vmin);
    }

    .voteCount {
      font-weight: bold;
      font-size: calc(10px + 2vmin);

    }
  }

}