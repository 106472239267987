body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #282c34;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.anychart-tooltip {
  text-align: start;
}

#map-container {
  width: 100%;
  height: 70vw;
  max-height: 85vh;
  margin-bottom: 20px;
}

.TopCandidates {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
  flex-wrap: wrap;
  align-self: stretch;
}

.PersonSearch {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  align-self: stretch;

  .SearchResults {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
    flex-wrap: wrap;
  }

  .searchField {
    position: relative;
    display: inline-block;

    input {
      width: 80vw;
      height: calc(10px + 2vmin);
      background-color: #0d0f11;
      outline: none;
      border: none;
      padding: 5px 10px;
      color: white;
    }

    .suggestions {
      margin-top: 1vmin;
      margin-left: calc(10vw - 10px);
      position: absolute;
      width: 80vw;
      padding: 5px 10px;
      background-color: #0d0f11;
    }
  }
}

.SplitRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.MandatesDistribution {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  margin: 20px 40px;
}

.ProgressBar {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  margin: 20px 40px;
}

.ProgressBarHolder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  min-height: 50px;
  max-height: 100px;
  flex-grow: 1;

  .progressBarEVotes {
    height: 30px;
    width: 30px;
    margin-right: 10px;
  }

  .progressBarNoColor {
    height: 30px;
  }

  .progressBarCounted {
    height: 30px;
    background: lightcyan;
  }

  .progressBarNotCounted {
    height: 30px;
    background: deepskyblue;
  }
}

footer {
  font-size: calc(10px + 0.5vmin);
  align-self: stretch;
  a {
    font-size: calc(10px + 0.5vmin);
    margin: 5px;
  }
}

.coalitionLabel {
  font-size: calc(10px + 0.5vmin);
  display: block;
  line-height: 30px;
  font-weight: bold;
}